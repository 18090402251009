import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from 'redux'
import axios from "axios";
import { ThunkAction } from 'redux-thunk'
import { RootState } from "../../store";
import { publishTimer, setTimer, Timer } from "./Timer";
import { useAppSelector } from "../../hooks";

const baseURL = 'https://api.stagetime.brightonmusical.com'

export const thunkCreateTimer = 
    (data: Object): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => 
    async (dispatch) => {
        CreateTimer(data).then((response) => {
            dispatch(setTimer(response.data));
        });
    }

export const thunkUpdateTimer = 
    (id: string, data: Object): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => 
    async (dispatch, getState) => {
        let oldTimer = getState().timers.items.find((timer) => timer.id == id);
        if(typeof oldTimer == "undefined") return;
        let newTimer = {...oldTimer, ...data};
        dispatch(setTimer(newTimer));
        try{
            UpdateTimer(id, data);
        }catch(error){
            console.error(error);
            dispatch(setTimer(oldTimer));
        }

    }

export const thunkDeleteTimer = 
    (timer: Timer): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        dispatch({"type": "timers/deleteTimer", "payload": timer});
        try{
            DeleteTimer(timer.id);
        }catch(error){
            console.error(error);
            dispatch({"type": "timers/setTimer", "payload": timer});
        }
    }

export const thunkSetPublished = 
    (id: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch, getState) => {
        console.log(id);
        const oldPublished = getState().timers.published;
        dispatch(publishTimer(id));
        try{
            PublishTimer(id);
        }catch(error){
            console.error(error);
            dispatch(publishTimer(oldPublished))
        }
    }


export function UpdateTimer(id: string, data: Object){
    return axios({
        method: "PATCH",
        url: `${baseURL}/timer/${id}`,
        data: data
    })
}

export function DeleteTimer(id: string){
    return axios({
        method: 'DELETE',
        url: `${baseURL}/timer/${id}`
    });
}

export function CreateTimer(data: Object){
    return axios({
        method: "POST",
        url: `${baseURL}/timer`,
        data: data
    })
}

export function PublishTimer(data: string){
    return axios({
        method: "POST",
        url: `${baseURL}/timer/published`,
        data: {"timer": data}
    })
}