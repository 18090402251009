const axios = require('axios');
const EventEmitter = require('events');

const WEBSOCKET_URL = "wss://38j0aawxof.execute-api.us-east-1.amazonaws.com/production";
const HTTP_BASE = "https://api.stagetime.brightonmusical.com";
const TIMESYNC_INTERVAL = 30000; 
const RETRY_DECAY = 1.5;
const INITIAL_RETRY_DELAY = 2000;
const MAX_RETRY_DELAY = 30000;
const PING_MAX_RESPONSE_TIME = 10000;

const WebSocket = require('isomorphic-ws');

export default class TimerClient extends EventEmitter {

    constructor(){
        super();

        this.timeOffset = 0;
        this.dataStore = {};
        this.publishedId = null;
        this.ws = null;
        let actionEmitter = new EventEmitter();
        this.timeSyncLoop = null;
        this.lastPingSentTime = null;
        this.lastPongRecvTime = 0;
        this.retryCount = 0;
        this.connectLock = false;

        actionEmitter.on("create", (timer) => {
            this.store(timer);
            this.emit("timer_created", timer);
        });

        actionEmitter.on("update", (timer) => {
            this.store(timer);
            this.emit("timer_updated", timer);
        });

        actionEmitter.on("delete", (timer) => {
            this.delete(timer);
            this.emit("timer_deleted", timer.id);
        });

        actionEmitter.on("published", (timer) => {
            this.publishedId = timer;
            this.emit("timer_published", timer);
        });

        actionEmitter.on("pong", (serverTime) => {
            console.log("PONG");
            const roundTripTime = Date.now() - this.lastPingTime;
            const estimatedTimeSinceServerTime = roundTripTime / 2;
            const estimatedCurrentServerTime = serverTime + estimatedTimeSinceServerTime;
            this.timeOffset = estimatedCurrentServerTime - Date.now();
            this.lastPongRecvTime = Date.now();
            this.emit("time_synced", this.currentTime);
        });

        this.actionEmitter = actionEmitter;
    }

    connect(){
        if(this.connectLock){
            return;
        }
        this.connectLock = true;
        let socket = new WebSocket(WEBSOCKET_URL);

        this.ws = socket;

        socket.addEventListener("open", async () => {
            this.retryCount = 0;
            this.emit("open");

            try{
                this.syncTime();
                await this.pullTimers();
            }catch(error){
                console.log(error);
            }

            this.emit("ready");

            this.timeSyncLoop = setInterval(function() {
                this.syncTime();
            }.bind(this), TIMESYNC_INTERVAL);
        });

        socket.addEventListener("close", () => {
            this.connectLock = false;
            clearInterval(this.timeSyncLoop);
            this.emit("close");

            let delay = Math.min(Math.pow(INITIAL_RETRY_DELAY / 1000, Math.pow(RETRY_DECAY, this.retryCount)) * 1000, MAX_RETRY_DELAY);
            setTimeout(function() {
                this.connect();
            }.bind(this), delay);

            this.retryCount++;
        });

        socket.addEventListener("message", (message) => {
            let json = message.data;
            let data = JSON.parse(json);

            console.log(data);

            this.actionEmitter.emit(data.action, data.data);
        });

        socket.addEventListener("error", (error) => {
            this.emit("error", error);
        });

    }

    store(timer){
        let oldVersion = -1;
        let timerId = timer.id;

        if(timerId == "published"){
            this.publishedId = timer.timer;
            return;
        }

        if(typeof this.dataStore[timerId] != "undefined"){
            oldVersion = this.dataStore[timerId].version;
        }

        if(timer.version >= oldVersion){
            this.dataStore[timerId] = timer;
        }
    }

    delete(timer){
        delete this.dataStore[timer.id];
    }

    get(id){
        return this.dataStore[id] ?? false;
    }

    syncTime(){
        this.lastPingTime = Date.now();
        this.ws.send(JSON.stringify({
            "action": "ping"
        }));
        setTimeout(function(){
            if((Date.now() - this.lastPongRecvTime) > PING_MAX_RESPONSE_TIME){
                this.ws.terminate();
            }
        }.bind(this), PING_MAX_RESPONSE_TIME);
    }

    pullTimers(){
        return new Promise((resolve, reject) => {
            axios.get(`${HTTP_BASE}/timer`)
            .then((response) => {
                let timers = response.data;
                this.dataStore = {};
                for(let i = 0; i < timers.length; i++){
                    this.store(timers[i]);
                }
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        })
    }

    close(){
        return this.close();
    }

    get timers(){
        return this.dataStore;
    }

    get publishedTimerID(){
        return this.publishedId;
    }

    get currentTime(){
        return Date.now() + this.timeOffset;
    }

}