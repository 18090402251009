import { ReactNode, useState } from 'react'

interface ActionMenuProps{
    dropdown: ReactNode,
    items: ActionMenuItem[]
}

interface ActionMenuItem{
    item: ReactNode,
    callback: () => void
    hide?: boolean
}

export default function ActionMenu(props: ActionMenuProps){

    const [dropped, setDropped] = useState(false);

    let items = props.items.filter((item) => !item.hide ?? true).map((item, index) => 
        <div key={index} onClick={item.callback}>
            {item.item}
        </div>
    );

    return(
        <div>
            <button className={"bg-dark-purple text-white text-sm px-3 py-2 rounded-md"} onClick={IO => setDropped(!dropped)}>
                {props.dropdown}
            </button>
            <div className={"relative"}>
                { dropped ? 
                <div className={"absolute top-0 right-0 rounded bg-dark-purple py-2"}>
                   {items}
                </div> : null }
            </div>
        </div>
    )

}

interface ActionProps{
    icon: ReactNode,
    text: string
}

export function Action(props: ActionProps){
    return(
        <div className={"px-3 py-1 pr-10 flex flex-row text-white space-x-3 hover:bg-light-purple"}>
            {props.icon}
            <p>{props.text}</p>
        </div>
    )
}