import { TimerType } from "../features/timers/Timer";
import DurationSelector from "./DurationSelector/DurationSelector";
import Modal, { ModalButtonType } from "./Modal";
import DateTimePicker from 'react-datetime-picker';
import { useState } from "react";
import {Duration} from 'luxon';

interface EditTimerModalProps{
    id?: string
    type: number
    name?: string
    time?: number
    duration?: number
    onDismiss: () => void
    onSave: (data: EditTimerModalResponse) => void
}

export interface EditTimerModalResponse{
    name?: string
    type: TimerType,
    duration?: number,
    time?: number
    state: string
}

export default function EditTimerModal(props: EditTimerModalProps){

    const {id, type, name, time, duration} = props;

    let actionString = (typeof id === "undefined") ? "New" : "Edit";
    let typeString = (type === TimerType.COUNTDOWN ? "Countdown" : "Timer");

    const [timerName, setTimerName] = useState(name ?? "");

    let dur = Duration.fromMillis(duration ?? 0);
    let durParts = dur.toFormat("hh:mm:ss").split(":");

    const [hours, setHours] = useState(parseInt(durParts[0]));
    const [minutes, setMinutes] = useState(parseInt(durParts[1]));
    const [seconds, setSeconds] = useState(parseInt(durParts[2]));

    const [dateTime, setDateTime] = useState((typeof time === "undefined") ? new Date() : new Date(time));

    const save = () => {
        let timer: EditTimerModalResponse = {
            name: timerName,
            type: type,
            duration: (type === TimerType.TIMER) ? ((hours * 3600) + (minutes * 60) + (seconds)) * 1000 : undefined,
            time: (type === TimerType.COUNTDOWN) ? dateTime.getTime() : undefined,
            state: (type === TimerType.COUNTDOWN) ? "RUNNING" : "STOPPED"
        }
        props.onSave(timer);
    }

    return(
        <Modal headerText={`${actionString} ${typeString}`} buttons={
            [
                {
                    type: ModalButtonType.CANCEL,
                    text: "Cancel"
                },
                {
                    type: ModalButtonType.CONTINUE,
                    text: "Save",
                    callback: save
                }
            ]
        } onDismiss={props.onDismiss}>
            <p>Name:</p>
            <input className={"w-full border bg-transparent rounded-md px-2 py-1 mt-1"} placeholder={`${typeString} Name`} value={timerName} onChange={(event) => setTimerName(event.target.value)} />

            <div className={"py-7"}>
                { type === TimerType.TIMER ? 
                    <div>
                        <p>Duration:</p>
                        <DurationSelector hours={hours} onHoursChange={setHours} minutes={minutes} onMinutesChange={setMinutes} seconds={seconds} onSecondsChange={setSeconds} />
                    </div>
                : 
                    <div>
                        <p className={"pb-1"}>Countdown Until:</p>
                        <DateTimePicker onChange={setDateTime} value={dateTime} />
                    </div>
                }
            </div>
            
        </Modal>
    )
}