import Modal, { ModalButtonType } from "./Modal";

import {CalendarIcon, ClockIcon} from '@heroicons/react/outline';
import { ReactNode, useState } from "react";

export interface TypeSelectionModalProps{
    onDismiss: () => void
    onSelected: (type: number) => void
}

export default function TypeSelectionModal(props: TypeSelectionModalProps){

    const [type, setType] = useState(-1);

    const next = () => {
        props.onSelected(type);
    }

    return(
        <Modal headerText={"New Clock"} buttons={
            [
                {
                    type: ModalButtonType.CANCEL,
                    text: "Cancel"
                },
                {
                    type: ModalButtonType.CONTINUE,
                    text: "Next",
                    disabled: type < 0,
                    callback: next
                }
            ]
        } onDismiss={props.onDismiss}>
            <p className={"pb-3"}>What type of clock would you like to create?</p>
            <div className={"flex flex-row justify-center items-center space-x-4"}>
                <TypeOptionBox selected={type === 0} onClick={() => setType(0)} icon={<ClockIcon className={"h-14 w-14"} />} title={"Timer"} description={"Counts down for a pre-determined elapsed time"}></TypeOptionBox>
                <TypeOptionBox selected={type === 1} onClick={() => setType(1)} icon={<CalendarIcon className={"h-14 w-14"} />} title={"Countdown"} description={"Counts down until a pre-determined timestamp"}></TypeOptionBox>
            </div>
        </Modal>
    )

}

interface TypeOptionBox{
    icon: ReactNode,
    title: string,
    description: string
    onClick: () => void
    selected: boolean
}

function TypeOptionBox(props: TypeOptionBox){

    let selectedClasses = ""; 

    if(props.selected) selectedClasses = "bg-light-grey text-black"

    return(
        <div onClick={props.onClick} className={`border rounded flex flex-col items-center justify-center px-4 py-5 hover:bg-light-grey hover:text-black ${selectedClasses}`}>
            {props.icon}
            <p className={"font-bold pt-4"}>{props.title}</p>
            <p>{props.description}</p>
        </div>
    )

}