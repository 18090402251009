import { useState } from 'react';
import EditTimerModal, { EditTimerModalResponse } from './EditTimerModal';
import TypeSelectionModal from './TypeSelectionModal';

export interface CreateModalWrapperProps{
    onDismiss: () => void
    onSubmit: (data: EditTimerModalResponse) => void
    showCreateModal: boolean
}

export default function CreateModalWrapper(props: CreateModalWrapperProps){

    const [type, setType] = useState(-1);

    return(
        <div>
            { props.showCreateModal ? 
                <TypeSelectionModal onDismiss={props.onDismiss} onSelected={(number) => setType(number)} />
            : null }
            { type >= 0 ? 
                <EditTimerModal onDismiss={() => setType(-1) } type={type} onSave={props.onSubmit} />
            : null }
        </div>
    )

}