import { thunkSetPublished } from "../features/timers/TimerService";
import { useAppDispatch, useAppSelector } from "../hooks"
import { ChangeEvent } from 'react';

export interface HeaderProps{
    createButtonAction: () => void
}

export default function Header(props: HeaderProps){

    const dispatch = useAppDispatch();

    let timers = useAppSelector((state) => state.timers.items);
    let published = useAppSelector((state) => state.timers.published);

    console.log(published);

    let options = timers.map((timer, index) => {
        if(typeof timer === "undefined") return;
        return <option key={index} value={timer.id}>{timer.name}</option>
    })

    options = [
        <option key={-1} value={undefined}>No Published Timer</option>,
        ...options
    ]

    const setTimer = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(thunkSetPublished(event.target.value));
    }

    return(
        <div className={"flex flex-row justify-between py-5"}>
            <h1 className={"text-white text-2xl basis-1/2"}>StageTime</h1>
            <div className={"flex flex-row space-x-3"}>
                <select value={published ?? -1} onChange={setTimer}> 
                    {options}
                </select>
                <button onClick={props.createButtonAction} className={"bg-dark-purple text-white px-7 py-3 rounded-3xl hover:bg-dark-purple-selected"}>Create</button>
            </div>
        </div>
    )

}