import { XIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";

export interface ModalProps{
    headerText: string
    children: ReactNode,
    buttons: ModalButtonProps[]
    onDismiss?: () => void
    hideCloseAction?: boolean
}

export default function Modal(props: ModalProps){

    let hideCloseAction = props.hideCloseAction ?? false

    const onActionClick = (callback?: () => void) => {
        if(typeof props.onDismiss != "undefined"){
            props.onDismiss();
        }
        if(typeof callback != "undefined"){
            callback();
        }
    }

    let buttons = props.buttons.map((button, index) => 
        <ModalButton key={index} type={button.type} text={button.text} disabled={button.disabled} callback={() => onActionClick(button.callback)} />
    )

    return(
        <div className={"flex flex-row items-center justify-center absolute top-0 left-0 bg-black/40 w-screen h-screen"}>
            <div className={"bg-dark-grey text-white py-5 px-8 rounded-xl min-w-[600px]"}>
                <div className={"flex flex-row justify-between items-center"}>
                    <h1 className={"text-3xl"}>{props.headerText}</h1>
                    { !hideCloseAction ? <XIcon onClick={props.onDismiss} className={"h-6 w-6x text-white/70 hover:text-white"} /> : null }
                </div>
                <div className={"my-6"}>
                    {props.children}
                </div>
                <div className={"flex flex-row justify-end space-x-2"}>
                    {buttons}
                </div>
            </div>
        </div>
    )
}

export interface ModalButtonProps{
    type: ModalButtonType,
    text: string,
    callback?: () => void,
    disabled?: boolean
}

export enum ModalButtonType{
    CANCEL = 0,
    CONTINUE,
    DESTRUCTIVE
}

export function ModalButton(props: ModalButtonProps){

    let classes = "border py-2 px-4 rounded-lg ";

    switch(props.type){
        case ModalButtonType.CANCEL:
            classes += "hover:text-black hover:bg-white"
        break;
        case ModalButtonType.CONTINUE:
            classes += "border-dark-purple bg-dark-purple hover:bg-dark-purple-selected hover:border-dark-purple-selected";
        break;
        case ModalButtonType.DESTRUCTIVE:
            classes += "border-red-600 bg-red-600 hover:border-red-700 hover:bg-red-700"
        break;
    }

    if(props.disabled ?? false){
        classes += " border-gray-500 bg-gray-500 hover:border-gray-500 hover:bg-gray-500"
    }

    return(
        <button className={classes} onClick={() => { if(!props.disabled && typeof props.callback !== "undefined") props.callback() } }>{props.text}</button>
    )
}