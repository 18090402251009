import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from './hooks'
import TimerClient from './features/websocket/TimerClient';
import Header from './components/Header';
import ListSection from './components/ListSection';
import { deleteTimer, publishTimer, setTimer } from './features/timers/Timer';
import TypeSelectionModal from './components/TypeSelectionModal';
import EditTimerModal, { EditTimerModalResponse } from './components/EditTimerModal';
import CreateModalWrapper from './components/CreateModalWrapper';
import { thunkCreateTimer } from './features/timers/TimerService';

function App() {

  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("APP CALLED");
    const timerClient = new TimerClient();
    
    timerClient.connect();

    timerClient.on("ready", () => {
      dispatch({"type": "websocket/connected"});
    });

    timerClient.on('ready', () => {
      let timers: any = timerClient.timers;
      let ids = Object.keys(timers);
      for(let i = 0; i < ids.length; i++){
        let id: string = ids[i];
        let timer: any = timers[`${id}`];
        dispatch(setTimer(timer));
      }
      dispatch(publishTimer(timerClient.publishedTimerID));
    });

    timerClient.on("timer_created", (timer) => {
      dispatch(setTimer(timer))
    });

    timerClient.on("timer_updated", (timer) => {
      dispatch(setTimer(timer))
    });

    timerClient.on("timer_deleted", (timer) => {
      dispatch(deleteTimer(timer))
    });

    timerClient.on("timer_published", (timerID) => {
      dispatch(publishTimer(timerID));
    });

    timerClient.on("time_synced", (currentTime: number) => {
      dispatch({"type": "timers/synchronize", "payload": Date.now() - currentTime});
    });

    return function cleanup(){
      timerClient.close();
    }
  }, [])

  const newTimer = (data: EditTimerModalResponse) => {
    dispatch(thunkCreateTimer(data));
  }

  const [showCreateModal, setShowCreateModal] = useState(false);

  const items = useAppSelector(state => state.timers.items);
  const published = useAppSelector(state => state.timers.published);

  const timers = items.filter(timer => timer.type == 0).sort((a, b) => a.duration - b.duration);
  const countdowns = items.filter(timer => timer.type == 1).sort((a, b) => a.time - b.time);;

  return (
    <div className={"bg-black min-h-screen"}>
      <div className={"container mx-auto"}>
        <Header createButtonAction={() => setShowCreateModal(true)} />
        <ListSection title="Timers" timers={timers}></ListSection>
        <div className={"my-10"}></div>
        <ListSection title="Countdowns" timers={countdowns}></ListSection>
      </div>

      <CreateModalWrapper showCreateModal={showCreateModal} onDismiss={() => setShowCreateModal(false)} onSubmit={newTimer} />

    </div>
  );
}

export default App;
