import React, { useState, useEffect, Component } from 'react';
import TimerActionsMenu, { Action } from "./ActionMenu";
import ActionMenu from "./ActionMenu";
import { ChevronDownIcon, PlayIcon, PauseIcon, XIcon, ClockIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Timer, getTimeLeft, TimerState, TimerType } from './Timer';
import { UpdateTimer, DeleteTimer, thunkUpdateTimer, thunkDeleteTimer } from './TimerService';
import { useAppSelector, useAppDispatch } from '../../hooks';
import Modal from '../../components/Modal';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import DelayModal from '../../components/DelayModal';
import EditTimerModal, { EditTimerModalResponse } from '../../components/EditTimerModal';
const { Duration } = require("luxon");

export default function TimerComponent(props: {timer: Timer}){

    const dispatch = useAppDispatch();

    const timeOffset = useAppSelector((state) => state.timers.timeOffset);

    const { id, name, time, duration, state, type } = props.timer;

    const [timeText, setTimeText] = useState("--:--");
    const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState(false);
    const [showingDelayDialogue, setShowingDelayDialogue] = useState(false);
    const [showingEditDialogue, setShowingEditDialogue] = useState(false);

    useEffect(() => {
        let interval = setInterval(() => {
            let timeLeft = getTimeLeft(props.timer, timeOffset);
            const timeText = msToCountdownText(timeLeft);
            setTimeText(timeText);
        }, 50);

        return(() => clearInterval(interval));
    }, [props.timer.time, props.timer.duration, props.timer.state])

    const start = () => {
        console.log("Starting " + id);
        dispatch(thunkUpdateTimer(id, {
            "state": "RUNNING",
            "time": getTimeLeft(props.timer, timeOffset) + (Date.now() + timeOffset)
        }));
    }

    const pause = () => {
        console.log("Pausing " + id);  
        dispatch(thunkUpdateTimer(id, {
            "state": "PAUSED",
            "time": getTimeLeft(props.timer, timeOffset)
        }))   
    }

    const reset = () => {
        console.log("Resetting " + id);
        dispatch(thunkUpdateTimer(id, {
            "state": "STOPPED", 
            "time": duration
        }))
    }

    const delay = (timeDelta: number) => {
        console.log(timeDelta);
        dispatch(thunkUpdateTimer(id, {
            "time": time + timeDelta
        }));
    }

    const edit = (data: EditTimerModalResponse) => {
        dispatch(thunkUpdateTimer(id, data));
    }

    const remove = () => {
        console.log("Deleting " + id);
        dispatch(thunkDeleteTimer(props.timer));
    }

    return(
        <div className={"bg-dark-grey"}>
            <div className={"py-5 px-4"}>
                <h1 className={"text-white pb-2"}>{name}</h1>
                <p className={"text-white text-4xl font-bold"}>{timeText}</p>
            </div>
            <div className={"border-t border-medium-grey py-2 px-2 flex justify-end"}>
                <ActionMenu dropdown={
                    <div>
                         Manage 
                        <ChevronDownIcon className={"inline h-6 w-6 font-extralight"} />
                    </div>
                } items={[
                    {
                        item: <Action icon={<PlayIcon className={"h-5 w-5"} />} text={"Start"} />,
                        callback: start,
                        hide: state === TimerState.RUNNING || type === TimerType.COUNTDOWN
                    },
                    {
                        item: <Action icon={<PauseIcon className={"h-5 w-5"} />} text={"Pause"} />,
                        callback: pause,
                        hide: state !== TimerState.RUNNING || type === TimerType.COUNTDOWN
                    },
                    {
                        item: <Action icon={<XIcon className={"h-5 w-5"} />} text={"Reset"} />,
                        callback: reset,
                        hide: state === TimerState.STOPPED || type === TimerType.COUNTDOWN
                    },
                    {
                        item: <Action icon={<ClockIcon className={"h-5 w-5"} />} text={"Adjust"} />,
                        callback: () => setShowingDelayDialogue(true),
                        hide: state !== TimerState.RUNNING
                    },
                    {
                        item: <Action icon={<PencilIcon className={"h-5 w-5"} />} text={"Edit"} />,
                        callback: () => setShowingEditDialogue(true)
                    },
                    {
                        item: <Action icon={<TrashIcon className={"h-5 w-5"} />} text={"Delete"} />,
                        callback: () => setShowingDeleteConfirmation(true)
                    }
                ]}></ActionMenu>
            </div>
            { showingDeleteConfirmation ?
                <DeleteConfirmModal onDismiss={() => setShowingDeleteConfirmation(false)} onConfirm={remove} />
            : null }
            { showingDelayDialogue ?
                <DelayModal onDismiss={() => setShowingDelayDialogue(false)} onConfirm={delay}></DelayModal>
            : null }
            { showingEditDialogue ? 
                <EditTimerModal id={id} type={type} name={name} duration={duration} time={time} onDismiss={() => setShowingEditDialogue(false)} onSave={edit}></EditTimerModal>
            : null }
        </div>
    )

}

function msToCountdownText(millis: number){
    millis = Math.max(0, millis);
    let dur = Duration.fromMillis(millis);
    let output = dur.toFormat("dd:hh:mm:ss")
    return output.replace(/^[0:]+(?=\d[\d:]{3})/, '');
}

