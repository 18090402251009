import Modal, { ModalButtonType } from "./Modal";

export interface DeleteConfirmModalProps{
    onDismiss: () => void
    onConfirm: () => void
}

export default function DeleteConfirmModal(props: DeleteConfirmModalProps){
    
    return(
        <Modal headerText={"Confirm Deletion"} buttons={[
            {
                type: ModalButtonType.CANCEL,
                text: "Cancel"
            },
            {
                type: ModalButtonType.DESTRUCTIVE,
                text: "Delete",
                callback: props.onConfirm
            }
        ]} onDismiss={props.onDismiss}>
            <p>This item will be deleted permanently. Do you wish to continue?</p>
        </Modal>
    )

}