export interface Timer{
    version: number,
    time: number
    id: string
    duration: number
    name: string
    state: TimerState,
    type: TimerType
}

export enum TimerState{
    STOPPED = "STOPPED",
    PAUSED = "PAUSED",
    RUNNING = "RUNNING",
}

export enum TimerType{
    TIMER = 0,
    COUNTDOWN = 1
}

export function publishTimer(id: string | null){
    return {
        "type": "timers/publishTimer",
        "payload": id
    }
}

export function setTimer(timer: Timer){
    return {
        "type": "timers/setTimer",
        "payload": timer
    }
}

export function deleteTimer(timer: Timer){
    return {
        "type": "timers/deleteTimer",
        "payload": timer
    }
}

export function getTimeLeft(timer: Timer, offset: number){
    switch(timer.state){
        case "RUNNING":
            return timer.time - (Date.now() + offset);
        case "STOPPED":
            return timer.duration;
        case "PAUSED":
            return timer.time;
        default:
            return 0;
    }
}