import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Timer } from './Timer';

/*
    {
        "version":16,
        "time":1654488880000,
        "id":"9ada1f58-51e2-45c6-a800-1db825b5cf8f",
        "duration":0,
        "name":"Unnamed Timer",
        "state":"RUNNING"
    }
*/

export interface TimerPayload{
    type: string
    timer: Timer
}

interface stateType {
    items: Timer[]
    published: string | null
    timeOffset: number
}

const initialState: stateType = {
    items: [],
    published: null,
    timeOffset: 0
}

const timersSlice = createSlice({
    name: 'timers',
    initialState,
    reducers: {
        setTimer: (state, action: PayloadAction<Timer>) => {
            console.log(action);
            const updatedTimer = action.payload;
            console.log(updatedTimer);
            const otherTimers = state.items.filter(timer => timer.id != updatedTimer.id)
            state.items = [
                ...otherTimers,
                updatedTimer
            ]
        },
        deleteTimer: (state, action: PayloadAction<Timer>) => {
            const deletedTimer = action.payload ;
            state.items = state.items.filter(timer => timer.id != deletedTimer.id);
        },
        publishTimer: (state, action: PayloadAction<string>) => {
            state.published = action.payload;
        },
        synchronize: (state, action: PayloadAction<number>) => {
            state.timeOffset = action.payload
        }
    }
})


export const { setTimer, deleteTimer } = timersSlice.actions

export default timersSlice.reducer