import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"
import React, { useState } from "react";

interface DurationSelectorProps{
    hours: number
    onHoursChange: (value: number) => void
    minutes: number
    onMinutesChange: (value: number) => void
    seconds: number
    onSecondsChange: (value: number) => void
}

export default function DurationSelector(props: DurationSelectorProps){

    return(
        <div className={"flex flex-row space-x-4 items-center"}>
            <NumberPicker value={props.hours} onChange={props.onHoursChange} name="Hours" min={0} />
            <NumberPicker value={props.minutes} onChange={props.onMinutesChange} name="Minutes" min={0} max={59} />
            <NumberPicker value={props.seconds} onChange={props.onSecondsChange} name="Seconds" min={0} max={59} />
        </div>
    )

}

interface NumberPickerProps{
    name: string
    value: number
    onChange: (value: number) => void
    min?: number
    max?: number
}

function NumberPicker(props: NumberPickerProps){

    const {name, value, onChange, min, max} = props;

    const valueChanged = (number: number) => {
        if(isNaN(number)){
            number = 0
        }
        if(typeof min != "undefined"){
            if(number < min) return;
        }
        if(typeof max != "undefined"){
            if(number > max) return;
        }
        onChange(number);
    }

    return(
        <div className={"flex flex-col space-y-0 text-center"}>
            <ChevronUpIcon className={"text-white h-[50px] hover:text-dark-purple"} onClick={() => valueChanged(value + 1)} />
            <input className={"text-2xl text-center text-white bg-transparent border-b mb-3 outline-0"} size={2} value={value} onChange={(event) => valueChanged(parseInt(event.target.value))} />
            <p className={"text-xs text-white text-center pt-2"}>{props.name}</p>
            <ChevronDownIcon className={"text-white h-[50px] hover:text-dark-purple"} onClick={() => valueChanged(value - 1)}/>
        </div>
    )

}