import DurationSelector from "./DurationSelector/DurationSelector";
import Modal, { ModalButtonType } from "./Modal";
import { useState } from 'react';

interface DelayModalProps {
    onDismiss: () => void
    onConfirm: (timeDelta: number) => void
}

export default function DelayModal(props: DelayModalProps){

    const [mode, setMode] = useState("delay");
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const confirm = () => {
        let multiplier = mode === "delay" ? 1 : -1;
        let durationMillis = multiplier * ((hours * 3600) + (minutes * 60) + seconds) * 1000;
        props.onConfirm(durationMillis);
    }

    return(
        <Modal headerText={"Delay/Expedite"} buttons={
            [
                {
                    text: "Cancel",
                    type: ModalButtonType.CANCEL
                },
                {
                    text: "Confirm",
                    type: ModalButtonType.CONTINUE,
                    callback: confirm
                }
            ]
        } onDismiss={props.onDismiss}>
            <p className={"pb-3"}>To delay or expedite the timer, set a duration below.</p>
            <div className={"flex flex-row items-center space-x-3"}>
                <select className={"text-black"} onChange={(event) => setMode(event.target.value)}>
                    <option value={"delay"}>Delay</option>
                    <option value={"hasten"}>Hasten</option>
                </select>
                <p> by </p>
                <DurationSelector hours={hours} onHoursChange={setHours} minutes={minutes} onMinutesChange={setMinutes} seconds={seconds} onSecondsChange={setSeconds} />
            </div>
        </Modal>
    )

}