import TimerComponent from '../features/timers/TimerComponent';
import { Timer } from '../features/timers/Timer';

export interface ListSectionProps{
    title: string;
    timers: Timer[]
}

export default function ListSection(props: ListSectionProps){

    const timers = props.timers;

    const renderedListItems = timers.map(timer => {
        return <TimerComponent key={timer.id} timer={timer} />
    })

    return (
        <div>
            <h1 className={"text-white text-xl mb-4"}>{props.title}</h1>
            { timers.length > 0 ? 
                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"}>
                    {renderedListItems}
                </div>
            : 
                <p className={"text-white"}>You don't have any clocks here yet. Create one with the Create button in the top-right corner.</p>
            }
        </div>
    )
}