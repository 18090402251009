import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface WebSocket{
    connected: boolean
    timeOffset: number
}

export interface Payload{
    action: PayloadActionTypes
    data: any
}

export interface PingPayload{
    action: PayloadActionTypes
    data: number
}

export enum PayloadActionTypes{
    CREATE,
    UPDATE,
    DELETE,
    PUBLISHED,
    PONG
}

const initialState: WebSocket = {
    connected: false,
    timeOffset: 0,
};

const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        connected(state){
            state.connected = true;
        },
        disconnected(state){
            state.connected = false;
        }
    }
});

export const { } = websocketSlice.actions

export default websocketSlice.reducer